;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.crm')
    .controller('CRMPostManagerController', CRMPostManagerController)
    .controller('CRMPostManagerSearchController', CRMPostManagerSearchController)

  function CRMPostManagerController($filter, $mdDialog, $scope, CRMService, PostManagerService) {
    $scope.loading = false

    $scope.load = function (event) {
      if ($scope.loading) {
        return
      }
      var params = {
        limit: $scope.filter.limit,
        page: $scope.filter.page,
      }
      $scope.loading = true
      PostManagerService.getCustomers(getParams(params))
        .then(function (response) {
          $scope.totalCustomers = response.total
          $scope.list = response.list
        })
        .finally(function () {
          $scope.loading = false
        })
    }

    $scope.search = function (event) {
      if ($scope.loading) {
        return
      }
      var dialog = {
        templateUrl: 'src/crm/views/dialog.postmngr.search.html',
        targetEvent: event,
        controller: 'CRMPostManagerSearchController',
        controllerAs: 'dialog',
        bindToController: true,
      }
      $mdDialog.show(dialog).then(function (query) {
        var params = {
          customerId: $scope.filter.customerId,
          queryField: query.field,
          queryText: query.text,
        }
        $scope.loading = true
        PostManagerService.getCustomers(params)
          .then(function (response) {
            $scope.totalCustomers = response.total
            $scope.list = response.list
            if (response.total == 1) {
              $scope.openCustomer(event, response.list[0].phonenumber)
            }
          })
          .finally(function () {
            $scope.loading = false
          })
      })
    }

    $scope.export = function (event) {
      if ($scope.loading) {
        return
      }
      $scope.loading = true
      PostManagerService.exportCustomers(getParams()).finally(function () {
        $scope.loading = false
      })
    }

    $scope.upload = function (event) {
      if ($scope.loading) {
        return
      }
      var dialog = $mdDialog.prompt({
        title: 'CSV Update',
        htmlContent:
          '<p>Eine <b>CSV-Datei</b> für die Aktualisierung mehrerer Datensätze hochladen.</p>' +
          '<p>Als Überschriften (erste Zeile) folgende Begriffe verwenden:</p>' +
          '<ul><li>ID-Nummer</li><li>Werbung</li><li>Deaktiviert</li><li>Unzustellbar</li></ul>',
        required: true,
        ok: 'Hochladen',
        cancel: 'Abbrechen',
        targetEvent: event,
        templateUrl: 'src/templates/dialog-file.html',
        locals: {
          accept: 'text/csv',
        },
      })
      $mdDialog.show(dialog).then(function (file) {
        var params = {
          customerId: $scope.filter.customerId,
        }
        $scope.loading = true
        PostManagerService.uploadChanges(params, file).finally(function () {
          $scope.loading = false
        })
      })
    }

    $scope.openCustomer = function (event, phonenumber) {
      $scope.loading = true
      CRMService.openCustomerDetails($scope.filter.customer, phonenumber).finally(function () {
        $scope.loading = false
      })
    }

    $scope.selection = {
      denyMailing: 0,
      deactivated: 0,
      undeliverable: 0,
      account: undefined,
    }

    function getParams(additional) {
      return Object.assign(
        {
          customerId: $scope.filter.customerId,
          storeId: $scope.filter.storeId,
          dateFrom: $filter('date')($scope.filter.dates.from),
          dateTo: $filter('date')($scope.filter.dates.to),
          denyMailing: $scope.selection.denyMailing,
          deactivated: $scope.selection.deactivated,
          undeliverable: $scope.selection.undeliverable,
          account: $scope.selection.account,
        },
        additional
      )
    }
  }

  function CRMPostManagerSearchController($mdDialog, $mdConstant) {
    this.$onInit = function () {
      this.hide = function () {
        $mdDialog.hide({
          field: this.queryField,
          text: this.queryText,
        })
      }
      this.abort = function () {
        $mdDialog.cancel()
      }
      this.keypress = function ($event) {
        var validPrompt = angular.isDefined(this.queryText)
        if ($event.keyCode === $mdConstant.KEY_CODE.ENTER && validPrompt) {
          this.hide()
        }
      }
    }
  }
})()
